import styled from "vue3-styled-component"

const select = { width: String }

export const Select = styled("select", select)`
	width: 37.5rem;
	height: 2.81rem;
	background: #fff;
	border: 1px solid #a9adcd;
	border-radius: 0.5rem;
	outline-color: #a9adcd;
	padding: 0 1rem;
	color: #09092a;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
	font-weight: 700;

	@media (min-width: 1023px) and (max-width: 1279px) {
		width: calc(100% - ${({ width }) => width as string});
	}
`
export const ItemsPerPage = styled.select`
	width: 3rem;
	height: 2rem;
	background: #fff;
	border: 1px solid #a9adcd;
	border-radius: 0.5rem;
	outline-color: #a9adcd;
	padding: 0 0.5rem;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
	font-weight: 700;
`
