/* eslint-disable no-useless-catch */
import { useClient } from "@/clients/AxiosClient"
import { Routes } from "@/utils/enum"

export const getCodRevSap = async (codRevSQL: string) => {
	try {
		const res: any = await useClient().get(
			`${Routes.GET_COD_REV_SAP}?codRevSQL=${codRevSQL}`
		)

		return res.data.message.data
	} catch (error: any) {
		throw error
	}
}
