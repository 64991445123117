<template>
	<main class="container">
		<router-view
			:key="$router.currentRoute.value.path.split('/')[0]"></router-view>
	</main>
</template>

<script setup lang="ts"></script>

<style>
.v-data-table__th {
	background: #e7e7e7 !important;
	color: #787888 !important;
	font-weight: 700 !important;
}

::-webkit-scrollbar {
	height: 0.25rem;
	width: 0.25rem;
	background-color: #e7e7e7;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(74, 217, 137, 0.5);
}

@media (min-width: 769px) and (max-width: 1450px) {
	.v-data-table__td {
		font-size: 0.8rem;
	}
}

th {
	border-bottom: 1px solid #949494 !important;
	border-top: 1px solid #949494 !important;
}

.overflow {
	overflow: hidden;
	-ms-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 0.3rem;
}

.overflow:hover {
	position: absolute;
	background: #fff;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
	border-radius: 0.25rem;
	color: #000;
	width: auto;
}

.overflow2 {
	height: 10rem;
	width: 100%;
	overflow-y: auto;
	overflow-x: auto;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background: rgba(62, 62, 62, 0.1);
}
</style>
