import { IResales, States, IResaleDetails } from "@/utils/interfaces"
import {
	SAVE_USER,
	SAVE_USER_REV,
	SAVE_RESALE_CODE,
	SAVE_USER_TYPE,
	SAVE_EXPIRED,
} from "../mutationsTypes"

export const users = {
	mutations: {
		[SAVE_USER](state: States, details: IResales[]) {
			state.userInfo = [...details]
		},
		[SAVE_USER_REV](state: States, details: IResaleDetails) {
			state.userRev = { ...details }
		},
		[SAVE_RESALE_CODE](state: States, details: string) {
			state.resaleCode = details
		},
		[SAVE_USER_TYPE](state: States, details: string) {
			state.userType = details
		},
		[SAVE_EXPIRED](state: States, details: number) {
			state.expired = details
		},
	},
}
