export const companies: { [key: string]: string[] } = {
	"Prime Interway MG": [
		"Nome empresarial: AIDC TECNOLOGIA LTDA",
		"Número de Inscrição: 07.500.596/0001-38 - Matriz",
		"Logradouro: Av. Poços de Caldas",
		"Número: 148",
		"Bairro/Distrito: Distrito Industrial",
		"Complemento: Galpão 1",
		"Município: Itajubá",
		"UF: MG",
		"CEP: 37.504-110",
	],
	"Prime Interway PR": [
		"Nome empresarial: AIDC TECNOLOGIA LTDA",
		"Número de Inscrição: 07.500.596/0004-80 - Filial",
		"Logradouro: Av. República Argentina",
		"Número: 665",
		"Bairro/Distrito: Água Verde",
		"Complemento: Conj: 206; Andar: 2",
		"Município: Curitíba",
		"UF: PR",
		"CEP: 80.240-210]",
	],
	"Prime Interway SC": [
		"Nome empresarial: AIDC TECNOLOGIA LTDA",
		"Número de Inscrição: 07.500.596/0006-42 - Filial",
		"Logradouro: Rod. Antonio Heil",
		"Número: 2030",
		"Bairro/Distrito: Itaipava",
		"Complemento: Salas 01, 02 e 03",
		"Município: Itajaí",
		"UF: SC",
		"CEP: 88.316-001",
	],
	"RB Code Matriz": [
		"Nome empresarial:  RB Code-Comercio e Serviços de Produtos e Soluções em TI LTDA",
		"Número de Inscrição: 07.586.045/0001-39 - Matriz",
		"Logradouro: Al. Rio Negro",
		"Número: 161",
		"Bairro/Distrito: Alphaville Centro Industrial e Empresarial",
		"Complemento: Edifício West Point, Andar 1, Sala 101",
		"Município: Barueri",
		"UF: SP",
		"CEP: 06.454-000",
	],
	"RB Code Filial": [
		"Nome empresarial: RB Code-Comercio e Serviços de Produtos e Soluções em TI LTDA",
		"Número de Inscrição: 07.586.045/0002-10 - Filial",
		"Logradouro: Al. Rio Negro",
		"Número: 161",
		"Bairro/Distrito: Alphaville Centro Industrial e Empresarial",
		"Complemento: Edifício West Point, Andar 1, Sala 101",
		"Município: Barueri",
		"UF: SP",
		"CEP: 06.454-000",
	],
}
