/* eslint-disable no-useless-catch */
import { useClient } from "@/clients/AxiosClient"
import { Routes } from "@/utils/enum"
import { IGetCommission } from "@/utils/interfaces"

const date = "2020-01-01"

export const getCommission = async (commission: IGetCommission) => {
	try {
		const data = {
			base: commission.base,
			pageNum: commission.pageNum,
			pageSize: commission.pageSize,
			intialDate: commission.initialDate != "" ? commission.initialDate : date,
			finalDate:
				commission.finalDate != ""
					? commission.finalDate
					: new Date().toISOString().split("T")[0],
			situation: commission.situation,
			codRev: commission.codRev,
			codClient: commission.client,
			isSelectedAll: false,
		}

		const res = await useClient().post(Routes.COMMISSION_PAGINATION, data)
		console.log("Fez o get ")
		return res.data
	} catch (error: any) {
		return error
	}
}

export const getItemByCommission = async (id: number, base: string) => {
	try {
		const data = {
			base,
			commissionId: id,
		}

		const res = await useClient().post(Routes.GET_ITEMS_BY_COMMISSION, data)

		return res.data
	} catch (error: any) {
		return error
	}
}

export const getAllCommissions = async (commission: IGetCommission) => {
	try {
		const data = {
			base: commission.base,
			intialDate: commission.initialDate != "" ? commission.initialDate : date,
			finalDate:
				commission.finalDate != ""
					? commission.finalDate
					: new Date().toISOString().split("T")[0],
			situation: commission.situation,
			codRev: commission.codRev,
			codClient: commission.client,
		}

		const res = await useClient().post(Routes.GET_ALL_COMMISSIONS, data)

		return res.data
	} catch (error) {
		return error
	}
}

export const getTotalCommissions = async (
	commission: IGetCommission,
	codRev: string
) => {
	try {
		if (codRev != "") commission.codRev = codRev

		const data = {
			base: commission.base,
			intialDate: commission.initialDate != "" ? commission.initialDate : date,
			finalDate:
				commission.finalDate != ""
					? commission.finalDate
					: new Date().toISOString().split("T")[0],
			situation: commission.situation,
			codRev: commission.codRev,
			codClient: commission.client,
		}

		const res = await useClient().post(Routes.GET_TOTAL_COMMISSIONS, data)

		return res.data
	} catch (error) {
		return error
	}
}
