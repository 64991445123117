import styled from "vue3-styled-component"

const Container = {
	top: String,
	background: String,
	w: String,
	h: String,
	L: String,
	bd: String,
	width: Number,
	height: Number,
}

export const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	height: 100vh;
	background: #f5f5f5;

	@media (min-width: 1023px) and (max-width: 1400px) {
		margin-bottom: 8rem;
	}
`

export const AcessContainer = styled.div`
	background: #fff;
	width: 38.4rem;
	height: 40rem;
	border-radius: 0.9375rem;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 2rem;
	padding: 2rem 0;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.8);
	position: absolute;
	top: 13rem;
	@media (min-width: 1023px) and (max-width: 1500px) {
		height: calc(100% - 25rem);
	}
	@media (max-height: 750px) {
		top: 10rem;
		height: calc(100vh - 5rem);
	}
`
export const LogoContainer = styled.div`
	background-image: url(${require("@/assets//wallpaper-blue.png")});
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
	height: 25rem;
	width: 100vw;
	display: flex;
	align-items: center;
	flex-direction: column;
	color: #fff;
	padding-top: 6rem;
	@media (max-height: 700px) {
		height: 22.8rem;
	}
	@media (max-width: 1450px) {
		padding-top: 4rem;
	}
`
export const InputContainer = styled("div", Container)`
	height: ${({ h }) => (h as string) || "15rem"};
	width: 30rem;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	position: relative;
	@media (min-width: 0) and (max-width: 767px) {
		width: 22rem;
	}
`
export const IconContainer = styled("div", Container)`
	position: absolute;
	top: ${({ top }) => top as string};
	left: ${({ L }) => (L as string) || "2rem"};
	@media (min-width: 0px) and (max-width: 767px) {
		left: -8.5rem;
	}
	color: #606060;
`
export const HomeContainer = styled.div`
	display: grid;
	grid-template-columns: 10% 90%;
	height: 100%;
`
export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 3rem 0 2rem;
	position: relative;
	height: 100%;
`

export const TotalValueContainer = styled.div`
	width: 17.75rem;
	height: 3.43rem;
	border: 2px solid #c9c9c9;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 0.5rem;
	color: #09092a;
`
export const PaginationContainer = styled.div`
	display: grid;
	grid-template-columns: 6% 3% auto 3% 2%;
	justify-content: space-evenly;
	padding-right: 1rem;
	align-items: center;
	color: #c9c9c9;
	width: 34%;
`
export const ItemsPerPageContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	padding-right: 1rem;
	align-items: center;
	width: 20%;
	color: #09092a;
`

export const TableColumnsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 22%);
	padding: 0.5rem 1rem;
	font-weight: 700;
`

export const SubTableRowsContainer = styled.div`
	border: 1px solid #e8e8e8;
	border-radius: 0.5rem;
	border-top-right-radius: 0;
	height: 80%;
	padding: 1rem 2rem;
	background: #fff;
	margin-top: 0.5rem;
	width: 100%;
	overflow-y: auto;
	overflow-x: auto;
`
export const ShowReasonContainer = styled.div`
	width: 28rem;
	height: 20rem;
	border-radius: 0.5rem;
	padding: 0.8rem;
	color: #09092a;
	overflow-y: auto;
	overflow-x: auto;
`
export const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 1;
	justify-content: center;
	backdrop-filter: blur(2px);
	background: rgba(255, 255, 255, 0.1);
`

export const ModalContentContainer = styled("div", Container)`
	width: ${({ w }) => (w as string) || "62.5rem"};
	height: ${({ h }) => (h as string) || "42.9rem"};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #f5f5f5;
	border-radius: 0.5rem;

	animation: fadeInAnimation ease 0.3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;

	@keyframes fadeInAnimation {
		0% {
			opacity: 0.3;
		}
		100% {
			opacity: 1;
		}
	}

	@media (min-width: 1023px) and (max-width: 1279px) {
		width: calc(100% - ${({ width }) => width as number}rem);
		height: calc(100vh - ${({ height }) => height as number}rem);
	}
`

export const ModalnfoNoteContainer = styled("div", Container)`
	width: 70rem;
	height: 42.9rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #fff;
	border-radius: 0.5rem;
	border: 1px solid #f6f6f6;
	z-index: 10;

	animation: fadeInAnimation ease 0.3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;

	@keyframes fadeInAnimation {
		0% {
			opacity: 0.3;
		}
		100% {
			opacity: 1;
		}
	}

	@media (min-width: 1023px) and (max-width: 1279px) {
		width: calc(100% - ${({ width }) => width as number}rem);
		height: calc(100vh - ${({ height }) => height as number}rem);
	}
`
export const LoadingContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 10;
	justify-content: center;
`

export const ModalLoading = styled.div`
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
`
export const NotificationContainer = styled.div`
	width: 43rem;
	height: 16.18rem;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 0.5rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #f5f5f5;
`
export const CommissionShowContainer = styled.div`
	width: 100%;
	height: 13rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border: 2px solid #cfd1e1;
	overflow-x: auto;
	overflow-y: auto;
	padding: 0 2rem;
`
export const DropzoneContainer = styled("div", Container)`
	border-color: ${({ bd }) => (bd as string) || "#cfd1e1"};
	border-width: 2px;
	width: 26rem;
	height: 2.81rem;
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;
	color: #09092a;
	font-weight: 700;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);

	@media (min-width: 1023px) and (max-width: 1279px) {
		width: calc(100% - 25rem);
	}
`
export const FileContainer = styled.div`
	width: 26rem;
	height: 2.81rem;
	border-color: #cfd1e1;
	border-radius: 0.5rem;
	border-width: 2px;
	padding: 0 1.25rem;
	font-weight: 700;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #4ad989;
`
