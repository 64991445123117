import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Loginpage from "../views/Loginpage.vue"
import Home from "../views/Homepage.vue"
import { Permission } from "@/utils/enum"
import { hasPermission, removeItems } from "@/utils/permissions"
import { isAuthenticated } from "../utils/permissions"
import FAQExternal from "../views/FaqExternalPage.vue"

import {
	setBearerAuthorization,
	useAuthClient,
	useClient,
} from "@/clients/AxiosClient"

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Login",
		component: Loginpage,
	},
	{
		path: "/Home",
		name: "Home",
		component: Home,
		meta: {
			protected: true,
			permissions: [Permission.ADMIN, Permission.RESALE],
		},
	},
	{
		path: "/Duvidas",
		name: "FAQ",
		component: FAQExternal,
	},
	{
		path: "/:catchAll(.*)",
		redirect: { name: "Login" },
		name: "NotFound",
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach(async (to, from, next) => {
	const token = isAuthenticated()

	if (token.length > 0) {
		setBearerAuthorization(useClient(), token)
		setBearerAuthorization(useAuthClient(), token)

		if (to.path == "/") {
			router.push("/Home")
		}
	}

	const permissions = to.meta.permissions as string[]

	if (to.meta.protected) {
		if (!hasPermission(permissions)) {
			alert("Você não possui a permissão necessária para acessar")
			removeItems()
			return next({ name: "Login" })
		}
	}

	return next()
})
export default router
