import { useClient } from "@/clients/AxiosClient"

import { Routes } from "@/utils/enum"

export const updatePdf = async (
	file: File[],
	noteNum: string,
	base: string,
	provision: number[],
	codRev: string,
	codRevSap: string
) => {
	try {
		const pdf = new FormData()

		const removeAccent = /[\u0300-\u036f]/g
		const removeSpecialCharacters = /[^a-zA-Z0-9]/g

		const fileName = file[0].name
			.normalize("NFD")
			.replace(removeAccent, "")
			.replace(removeSpecialCharacters, "")
			.replace("pdf", ".pdf")

		file.forEach((file) => {
			pdf.append("file", file, fileName)
		})
		pdf.append("base", base)
		pdf.append("notafiscal", noteNum)
		provision.forEach((p) => pdf.append("provisoes", String(p)))
		pdf.append("codRevendaSQL", codRev)
		pdf.append("codRevendaSAP", codRevSap)

		const response = await useClient().post(
			Routes.CREATE_PURCHASE_QUOTATION,
			pdf
		)

		return response
	} catch (error) {
		return error
	}
}
