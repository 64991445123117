import { useClient } from "@/clients/AxiosClient"
import { Routes } from "@/utils/enum"
import { IGetCommission } from "@/utils/interfaces"

export const downloadReport = async (commission: IGetCommission) => {
	try {
		const date = "2020-01-01"

		const data = {
			base: commission.base,
			intialDate: commission.initialDate != "" ? commission.initialDate : date,
			finalDate:
				commission.finalDate != ""
					? commission.finalDate
					: new Date().toISOString().split("T")[0],
			situation: commission.situation,
			codRev: commission.codRev,
			codClient: commission.client,
		}

		const res = await useClient().post(Routes.DOWNLOAD_REPORT, data, {
			responseType: "blob",
		})

		return res
	} catch (error) {
		return error
	}
}
