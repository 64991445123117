import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { key, store } from "./store"
import "vuetify/styles"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import "./index.css"
import { setupClient } from "./clients/AxiosClient"
import { createHead } from "@vueuse/head"

const head = createHead()

setupClient(process.env.VUE_APP_BACK, process.env.VUE_APP_AUTH)

const vuetify = createVuetify({
	components,
	directives,
})

createApp(App).use(store, key).use(head).use(vuetify).use(router).mount("#app")
