<template>
	<v-snackbar color="red-accent-2" style="height: 96%">
		<p class="text_style">
			Você está tentando enviar um número de comissões que ultrapassa o limite
			de 80 linhas de comissão por Nota Fiscal Eletrônica (NFE).
		</p>
		<p class="text_style">
			Por favor, desmarque algumas comissões para prosseguir com o envio.
		</p>
	</v-snackbar>
</template>
<style>
.text_style {
	text-align: center !important;
	font-weight: 500;
}
</style>
