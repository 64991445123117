import styled from "vue3-styled-component"

const ValidationError = { validationError: Boolean }
const input = { w: String, h: String, br: String, color: String, width: String }

export const LoginInput = styled("input", ValidationError)`
	background:#F6F6F6;
	box-shadow: 0 0.3rem 0.62rem #F6F6F6;
	padding: 0 5rem;
	border-radius: 6.25rem;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
	border: ${(props) => (props.validationError ? "1px" : 0)} solid ${(props) =>
	props.validationError ? "#f00" : "#F6F6F6"};
	font-weight: 700;
	font-size: 2rem
	width: 30rem;
    outline-color: #F6F6F6;
	height: 4.75rem;
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input {
		-moz-appearance: textfield;
	}
`
export const InputReason = styled.input`
	height: 2.18rem;
	background: #fff;
	border: 1px solid #a9adcd;
	border-radius: 5rem;
	padding: 1rem;
	outline-color: #a9adcd;
	color: #09092a;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
	font-weight: 700;
	::placeholder,
	::-webkit-input-placeholder {
		color: #a9adcd;
	}
	:-ms-input-placeholder {
		color: #a9adcd;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input {
		-moz-appearance: textfield;
	}
`
export const Input = styled("input", input)`
	width: ${({ w }) => (w as string) || "18rem"};
	height: ${({ h }) => (h as string) || "2.81rem"};
	background: #fff;
	border: 1px solid #a9adcd;
	border-radius: ${({ br }) => (br as string) || "0.5rem"};
	outline-color: #a9adcd;
	padding: 1rem;
	color: ${({ color }) => (color as string) || "#09092a"};
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
	font-weight: 700;
	::placeholder,
	::-webkit-input-placeholder {
		color: #a9adcd;
	}
	:-ms-input-placeholder {
		color: #a9adcd;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input {
		-moz-appearance: textfield;
	}

	@media (min-width: 1023px) and (max-width: 1279px) {
		width: calc(100% - ${({ width }) => width as string});
	}
`
export const TextArea = styled.textarea`
	border: 1px solid #a9adcd;
	width: 100%;
	height: auto;
	background: #ffffff;
	border-radius: 0.5rem;
	padding: 1rem;
	margin-bottom: 2rem;
	resize: none;
	outline-color: #a9adcd;
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
	font-weight: 700;
`
