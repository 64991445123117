import { IGetCommission, States } from "@/utils/interfaces"
import { SAVE_COMISSION_FILTER } from "../mutationsTypes"

export const commission = {
	mutations: {
		[SAVE_COMISSION_FILTER](state: States, details: IGetCommission) {
			state.commissionsFilter = { ...details }
		},
	},
}
