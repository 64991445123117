import styled from "vue3-styled-component"

const Condicional = {
	enableButton: {
		type: Boolean,
		default: false,
	},
	w: String,
	h: String,
}

const Buttons = {
	background: String,
	color: String,
	bd: String,
	w: String,
	h: String,
}

export const LoginButton = styled("button", Condicional)`
	width: 30rem;
	height: 4.75rem;
	background: ${({ enableButton }) => (enableButton ? "#4ad989" : "#9D9797")};
	border-radius: 2.25rem;
	color: #fff;
`
export const Button = styled("button", Buttons)`
	width: ${({ w }) => (w as string) || "8.5rem"};
	height: ${({ h }) => (h as string) || "3.18rem"};
	border-radius: 2.25rem;
	background: ${({ background }) => background as string};
	color: ${({ color }) => color as string};
	font-weight: 800;
	border: 2px solid ${({ bd }) => bd as string};
`

export const EditButton = styled.button`
	background: #4ad989;
	color: #fff;
	width: 10.3rem;
	height: 2.18rem;
	border-radius: 0.5rem;
`
export const ModalButton = styled("button", Condicional)`
	width: ${({ w }) => (w as string) || "18.3rem"};
	color: #fff;
	background: ${({ enableButton }) => (enableButton ? "#4ad989" : "#9D9797")};
	border-radius: 2.25rem;
	height: ${({ h }) => (h as string) || "4.75rem"};
`
export const ModalCloseButton = styled("button", Buttons)`
	width: ${({ w }) => (w as string) || "18.3rem"};
	color: #606060;
	background: #f5f5f5;
	border: 2px solid #cfd1e1;
	border-radius: 2.25rem;
	height: 4.75rem;
`
export const ChangePasswordButton = styled.button`
	width: 11rem;
	height: 3rem;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	border: 2px solid #dadada;
	font-weight: 700;
`
export const LogoutButton = styled.button`
	width: 11rem;
	height: 3rem;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	border: 2px solid #dadada;
	font-weight: 700;
`
export const DownloadButton = styled.button`
	width: 10.18rem;
	height: 3.18rem;
	background: #fff;
	border: 2px solid #dadada;
	font-weight: 700;
	border-radius: 2.25rem;
`
export const ExpandButton = styled.button`
	border: 2px solid #dadada;
	border-bottom: transparent;
	width: 5rem;
	color: #606060;
	float: right;
`
