/* eslint-disable no-useless-catch */
import { useAuthClient } from "@/clients/AxiosClient"
import { IResale, IUpdateUser } from "@/utils/interfaces"
import { Routes } from "@/utils/enum"
import { getCodRevSap } from "./getCodRevSap"

const role = process.env.VUE_APP_ROLE_REVENDA

export const getAllUsers = async (page: number, amount: number) => {
	try {
		const res = await useAuthClient().get(
			`${Routes.AUTH_GET_ALL_USER}?role=${role}&page=${page}&amount=${amount}`
		)

		return { status: res.status, data: res.data.data }
	} catch (error: any) {
		return error
	}
}

export const getUserById = async (id: string) => {
	try {
		const res: any = await useAuthClient().get(`${Routes.AUTH_GET_USER}/${id}`)
		const index = Array.from(res.data.data.role).findIndex((e: any) => {
			// eslint-disable-next-line
			if (e.name == process.env.VUE_APP_ROLE_REVENDA) {
				return true
			} else if (e.name == process.env.VUE_APP_ROLE_REVENDA_ADMIN) {
				return true
			}

			return false
		})
		const roleComissao = res?.data.data.role[index]
		const username = res?.data.data.username
		//const codRev = res.data.data.role[0].data.codRev
		const codRev = roleComissao?.data.codRev
		const user = res?.data.data
		//const aidc = res.data.data.role[0].data.AIDC
		const aidc = roleComissao?.data.AIDC
		//const rbcode = res.data.data.role[0].data.RBCODE
		const rbcode = roleComissao?.data.RBCODE

		return { username, codRev, aidc, rbcode, user }
	} catch (error: any) {
		return error
	}
}

export const getUsername = async (username: string) => {
	try {
		const res = await useAuthClient().get(
			`${Routes.AUTH_GET_USER}?role=${role}&username=${username}`
		)

		return res
	} catch (error) {
		return error
	}
}

export const getCodRev = async (codRev: string) => {
	try {
		const res = await useAuthClient().get(
			`${Routes.AUTH_GET_ALL_USER}?role=${role}&data_eq=codRev,${codRev}`
		)

		return res
	} catch (error) {
		return error
	}
}

export const updateUsers = async (
	id: string | undefined,
	user: IUpdateUser
) => {
	try {
		const data: Partial<IUpdateUser> = {}

		for (const key in user) {
			if (
				user[key as keyof IUpdateUser] !== "" &&
				user[key as keyof IUpdateUser] !== undefined
			) {
				data[key as keyof IUpdateUser] = user[key as keyof IUpdateUser]
			}
		}

		const res = await useAuthClient().put(`${Routes.AUTH_GET_USER}/${id}`, data)

		return res
	} catch (error: any) {
		return error
	}
}

export const createUser = async (user: IResale) => {
	try {
		const roleName = process.env.VUE_APP_ROLE_REVENDA
		const redirect_url = process.env.VUE_APP_REDIRECT_URL

		let cod: any
		let AIDC: string
		let RBCODE: string

		try {
			cod = await getCodRevSap(user.resaleCode)
			AIDC = cod.codRevSAP.AIDC
			RBCODE = cod.codRevSAP.RBCODE
		} catch (error) {
			throw { status: 404 }
		}

		const data = {
			company: "prime",
			redirect_url,
			username: user.username.trim(),
			name: user.name.trim(),
			email: user.email.trim(),
			role: [
				{
					name: roleName,
					data: {
						codRev: user.resaleCode.trim(),
						AIDC: AIDC,
						RBCODE: RBCODE,
					},
				},
			],
		}

		const res = await useAuthClient().post(Routes.AUTH_GET_USER, data)

		return res
	} catch (error: any) {
		return error
	}
}
