import { useClient } from "@/clients/AxiosClient"
import { Routes } from "@/utils/enum"
import { IEmail } from "@/utils/interfaces"

export const sendEmailToTax = async (content: IEmail) => {
	try {
		const data = {
			subject: content.subject,
			content: content.content,
			resselerEmail: content.email,
			username: content.username,
			name: content.resale,
			codRev: content.codRev,
		}

		const res: any = await useClient().post(Routes.SEND_EMAIL, data)

		return res
	} catch (error) {
		return error
	}
}
