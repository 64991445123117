export const MAX = 999999999999

export const setPermission = (permissions: Array<string>) => {
	return localStorage.setItem("user_permissions", JSON.stringify(permissions))
}

export const getPermission = (): Array<string> => {
	return JSON.parse(localStorage.getItem("user_permissions") || "[]")
}

export const getExp = (): number => {
	const data = localStorage.getItem("exp")

	if (data) {
		const exp = JSON.parse(data)
		return exp == typeof "number" ? exp : MAX
	}

	return MAX
}

export const removeItems = () => {
	localStorage.removeItem("token")
	localStorage.removeItem("refreshToken")
	localStorage.removeItem("exp")
	localStorage.removeItem("user_permissions")
}

export const hasPermission = (permission: string[]) => {
	return getPermission().filter((e) => permission.includes(e)).length > 0
}

export const setIsAuthenticated = (token: string, refreshToken: string) => {
	localStorage.setItem("token", JSON.stringify(token))
	localStorage.setItem("refreshToken", JSON.stringify(refreshToken))

	return
}

export const setSendPermission = (data: any) => {
	localStorage.setItem("sending_permissions", JSON.stringify(data))
	return
}

export const getSendingPermission = () => {
	return JSON.parse(localStorage.getItem("sending_permissions") ?? "{}")
}

export const checkCompanySendingPermission = (base: string): boolean => {
	return getSendingPermission()[base] ?? false
}

export const revokeCompanySendingPermission = (company: string) => {
	const data = JSON.parse(localStorage.getItem("sending_permissions") ?? "{}")
	data[company] = false
	setSendPermission(data)
	return
}

export const setExp = (exp: number) => {
	localStorage.setItem("exp", JSON.stringify(exp))
}

export const isAuthenticated = (): string => {
	return JSON.parse(localStorage.getItem("token") || "[]")
}

export const getRefreshToken = (): string => {
	return JSON.parse(localStorage.getItem("refreshToken") || "[]")
}
