export enum TypeOfUser {
	USER = "Usuário",
	COMMISSION = "Comissão",
}

export enum Permission {
	RESALE = "COMISSAO_REVENDA",
	ADMIN = "COMISSAO_REVENDA_ADMIN",
}

export enum Situations {
	FUTURE_COMMISSIONS = "Comissões futuras",
	AVAILABLE_COMMISSIONS = "Comissões disponíveis",
	GRADES_SENT = "Notas enviadas",
	COMMISSIONS_PAID = "Comissões pagas",
}

export enum COMPANIES {
	PRIME_MG = "Prime Interway MG",
	PRIME_PR = "Prime Interway PR",
	PRIME_SC = "Prime Interway SC",
	RB_CODE_HEADQUARTERS = "RB Code Filial",
	RB_CODE_BRANCH = "RB Code Matriz",
}

export enum Notification {
	TITLE_NOTE_SENT = "Nota enviada!",
	SUBTITLE_NOTE_SENT = "Sua nota foi enviada com sucesso. ",
	TITLE_ERROR_SEND_NOTE = "Erro ao enviar a nota",
	SUBTITLE_ERROR_SEND_NOTE = "Tivemos um ero inesperado, por favor refaça a operação",
	TITLE_ADJUSTED_COMMISSION = "Comissão ajustada!",
	SUBTITLE_ADJUSTED_COMMISSION = "O ajuste do valor de comissão foi realizado com sucesso.",
	TITLE_ERROR_ADJUSTED_COMMISSION = "Erro ao ajustar comissão",
	SUBTITLE_ERROR_ADJUSTED_COMMISSION = "Verifique os dados inseridos e refaça a operação",
	TITLE_REGISTERED_USER = "Usuário cadastrado!",
	SUBTITLE_REGISTERED_USE = "O usuário foi cadastrado com sucesso.",
	TITLE_USER_EDIT = " Edição de usuário!",
	SUBTITLE_USER_EDIT = "Sucesso na edição! As informações foram atualizadas com êxito.",
	TITLE_ERROR = "Tivemos um erro inesperado",
	SUBTITLE_ERROR = "Por favor, verifique as informações ou refaça a operação",
	TITLE_COMMISSION_WITHOUT_ADJUSTMENT = "Comissão sem ajuste",
	SUBTITLE_COMMISSION_WITHOUT_ADJUSTMENT = "Essa comissão ainda não possui ajustes realizados",
	TITLE_CODE_NOT_FOUND = "Código não encontrado",
	SUBTITLE_CODE_NOT_FOUND = "O código do cadastro de parceiro não foi encontrado",
	TITLE_EXISTING_USER = "Usuário já cadastrado",
	SUBTITLE_EXISTING_USER = "Já existe um usuário cadastrado com esse nome",
	TITLE_COMMISSION_NOT_FOUND = "Comissão não encontrada",
	SUBTITLE_COMMISSION_NOT_FOUND = "Não foram encontradas comissões ",
	TITLE_UPDATE_ERROR = "Erro na atualização",
	SUBTITLE_UPDATE_ERROR = "Por favor modifique as informações para atualizar",
	TITLE_SESSION_EXPIRED = "Sessão expirada",
	SUBTITLE_SESSION_EXPIRED = "Você será redirecionado para fazer o login novamente",
	TITLE_DOUBT_SENT = "Dúvida enviada",
	SUBTITLE_DOUBT_SENT = "Sua dúvida foi enviada com sucesso",
}

export enum Routes {
	AUTH_LOGIN = "/auth/login",
	COMMISSION_PAGINATION = "/commissions-paginated",
	AUTH_PAYLOAD = "/auth/payload",
	AUTH_RESET_PASSWORD = "/auth/reset_password",
	GET_ITEMS_BY_COMMISSION = "/itens-by-commission-id",
	AUTH_GET_USER = "/user",
	AUTH_GET_ALL_USER = "/user/by-role",
	GET_COD_REV_SAP = "/cod-rev-sap",
	GET_ADJUSTMENTS = "/get-adjustment",
	INSERT_ADJUSTMENTS = "/insert-adjustment",
	CREATE_PURCHASE_QUOTATION = "/create-purchase-quotation",
	GET_ALL_COMMISSIONS = "/all-commissions",
	GET_TOTAL_COMMISSIONS = "/commissions-amount",
	DOWNLOAD_REPORT = "/create-report",
	REFRESH_TOKEN = "/auth/refresh_token",
	SEND_EMAIL = "/send-faq",
	GET_SEND_PERMISSION = "/verify-sending-permission",
}

export enum Bases {
	INTERWAY = "INTERWAY",
	INTERWAYPR = "INTERWAYPR",
	INTERWAYSC = "INTERWAYSC",
	PRIME = "PRIME",
	PRIMESP = "PRIMESP",
}

export enum Status {
	PAID = "PAGAS",
	SENT = "ENVIADAS",
	AVAILABLE = "DISPONIVEIS",
	FUTURE = "FUTURAS",
}

export enum Profile {
	COMMISSION = "COMMISSION",
	USER = "USER",
	FAQ = "FAQ",
}
