export const answers = {
	getCommissions: [
		"Para solicitar suas comissões, siga estes passos:",
		" 1. Faça login no portal de comissões revenda utilizando suas credenciais.",
		" 2. Clique no botão Pesquisar localizado no painel esquerdo da tela.",
		"  3. Preencha o filtro com as informações relevantes, como o período em que  a nota foi emitida, a empresa e a situação como Comissões disponíveis.",
		"  4. Verifique se todos os campos estão corretos e clique em Pesquisar.",
		"  5. Aguarde os resultados da busca.",
		"6. Selecione as comissões que deseja reivindicar marcando a caixa localizada na primeira coluna do painel, na linha correspondente à comissão desejada.",
		"- Se desejar selecionar todas as notas disponíveis, clique no botão Buscar todas e marque a caixa localizada na primeira coluna. Assim, todas as comissões presentes no painel serão selecionadas.",
		"    7. Clique no botão Enviar nota.",
		"    8. Aguarde a abertura do modal de envio.",
		"9. Verifique na janela principal do modal se as comissões exibidas são as selecionadas anteriormente e se seus dados estão corretos.",
		" 10. Anexe o documento em PDF da nota fiscal referente às comissões selecionadas.",
		" 11. Verifique se o nome do arquivo está de acordo com o padrão indicado: NF [n° NF] [Nome Revenda].",
		"  12. Verifique se o valor total da nota fiscal está igual ao valor exibido no modal de envio.",
		"    13. Insira o número da nota fiscal, sem caracteres especiais ou espaços.",
		"   14. Clique em Enviar.",
		"15. Aguarde a mensagem de sucesso de envio.",
	],

	changePassword: [
		" Saiba o que fazer caso queira trocar sua senha:",
		"   - Na tela de login, você encontrará a opção 'Esqueceu a senha?'. Clique nessa opção para ser redirecionado para uma nova tela.",
		"  	- Insira seu nome de usuário na caixa designada e clique em 'Enviar'. Um link para redefinição de senha será enviado para o e-mail associado à sua conta.",
		" 	- Aguarde alguns instantes e verifique sua caixa de entrada. Caso não receba o e-mail, verifique também sua pasta de spam.",
		"   - Ao receber o e-mail, clique no link fornecido. Você será redirecionado para uma página onde poderá definir uma nova senha.",
		"   - Digite sua nova senha, confirme-a e conclua o processo de redefinição.",
		"  	- Após esse processo, sua senha será alterada e você poderá tentar acessar o portal novamente utilizando sua nova senha.",
	],

	wrongUser: [
		"Saiba o que fazer caso seu nome de usuário esteja incorreto:",
		"  1. Acesse o e-mail vinculado à sua conta:",
		"		-Abra o e-mail recebido da Prime Interway que contém informações de sua conta. Utilize a caixa de busca do seu e-mail e procure pelo nome da 'Primeinterway'. Verifique se os dados fornecidos no e-mail estão corretos, incluindo o nome de usuário. Certifique-se de inserir as informações exatamente como estão indicadas no e-mail.",
		" 2. Caso não encontre o e-mail:",
		"		-Verifique se está procurando no e-mail correto. É possível que você esteja procurando no e-mail errado, que não está vinculado à sua conta na plataforma. Certifique-se de estar acessando o e-mail que está associado à sua conta no Portal Comissão Revenda.",
		" 3. Se ainda não encontrar o e-mail ou estiver com dificuldades:",
		"		-Caso não consiga encontrar o e-mail ou esteja com dificuldades para localizar suas informações de usuário corretas, é recomendado entrar em contato diretamente com a equipe de suporte da Prime Interway. Eles estarão prontos para auxiliá-lo(a) a encontrar seus dados corretos e resolver qualquer problema relacionado ao seu nome de usuário.",
	],

	login: [
		"	Saiba o que fazer caso esteja enfrentando dificuldades para fazer login na plataforma.",

		"1. Reinicie seu navegador:",
		"   - Feche completamente o navegador em que está tentando acessar a plataforma.",
		"  	- Aguarde alguns segundos e abra o navegador novamente.",
		"   - Tente fazer login na plataforma após reiniciar o navegador.",

		"2. Verifique sua conexão de internet:",
		"		- Verifique se você está conectado à internet de forma estável.",
		"   - Caso esteja utilizando uma conexão Wi-Fi, verifique se está conectado à rede correta e se o sinal está forte o suficiente.",
		" 	- Se estiver usando uma conexão por cabo, verifique se o cabo está corretamente conectado ao seu dispositivo e ao roteador.",

		"3. Valide se seus dados estão corretos:",
		" 	- Certifique-se de que está inserindo corretamente seu nome de usuário e senha.",
		"   - Verifique se não há espaços extras antes ou depois das informações inseridas.",
		"		- Caso esteja com dificuldades para lembrar sua senha, utilize a opção de redefinição de senha disponível na tela de login.",
	],
	bankDetailsChanges: [
		"Em caso de alteração de dados bancários da sua empresa, preencha o formulário ao lado seguindo os passos abaixo para envio das suas informações.",
		"Assunto:",
		"Preencha o campo “Assunto” com: Alteração de dados bancários para pagamento de notas de comissão",
		"No corpo do formulário, coloque as seguintes informações:",
		"1 - Código do Banco",
		" P: O que é o Código do Banco?",
		"R: O Código do Banco é um número específico que identifica cada instituição financeira. Ao realizar a alteração dos dados bancários, é importante fornecer o código correto do banco para o qual os pagamentos devem ser direcionados.",
		"2 - Banco",
		'P: Qual é o significado de "Banco" no formulário de alteração de dados?',
		"R: Neste campo, deve-se informar o nome da instituição financeira onde o beneficiário possui sua conta para recebimento das comissões. É importante escrever o nome completo e correto do banco.",
		"3 - Agência",
		'P: O que devo preencher no campo "Agência"?',
		'R: O campo "Agência" deve ser preenchido com o número da agência onde o beneficiário mantém sua conta bancária. Certifique-se de inserir os números corretamente para evitar erros nos pagamentos.',
		"4 - Conta Corrente de PJ",
		'P: O que é a "Conta Corrente de PJ"?',
		'R: A "Conta Corrente de PJ" refere-se à conta bancária da pessoa jurídica (empresa) que receberá os pagamentos das notas de comissão. Certifique-se de fornecer os dados precisos da conta corrente empresarial.',
		"5 - CNPJ",
		'P: O que é o "CNPJ" e por que é necessário para a alteração de dados bancários?',
		"R: O CNPJ (Cadastro Nacional da Pessoa Jurídica) é o registro fiscal que identifica uma empresa perante a Receita Federal. Ele é fundamental para garantir que os pagamentos sejam direcionados à empresa correta, evitando possíveis problemas de identificação.",
		"6 - Chave PIX atrelada a CC",
		'P: O que significa a "Chave PIX atrelada a CC"?',
		'R: A "Chave PIX atrelada a CC" é uma chave de identificação no sistema de pagamentos instantâneos PIX que está vinculada à conta corrente informada para receber os pagamentos. É um método ágil e seguro para realizar transferências.',
		"Lembramos a importância de fornecer todas as informações com precisão ao realizar a alteração dos dados bancários. Isso assegura que os pagamentos sejam processados adequadamente, evitando quaisquer inconvenientes futuros. Se ainda houver dúvidas ou necessidade de assistência, nossa equipe de suporte estará à disposição para ajudá-lo. ",
	],
}
