import styled from "vue3-styled-component"

export const Title = styled.h1`
	font-size: 3rem; //48px
	font-weight: 700;
`
export const H1 = styled.h1`
	font-size: 2.5rem; //40px
	font-weight: 700;
`
export const H2 = styled.h2`
	font-size: 1.25rem; //20px
	font-weight: 400;
	text-align: center;
`
export const H3 = styled.h3`
	font-size: 1rem; //10px
	font-weight: 300;
	text-align: center;
`
export const Paragraph = styled.p`
	font-size: 1rem; //16px
	font-weight: 700;
	text-align: center;
`
