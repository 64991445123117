import {
	setBearerAuthorization,
	useAuthClient,
	useClient,
	setContentType,
} from "@/clients/AxiosClient"
import { IUser } from "@/utils/interfaces"
import { setIsAuthenticated, setPermission } from "@/utils/permissions"
import { Routes } from "@/utils/enum"

export const signin = async (user: IUser) => {
	try {
		const config = {
			auth: { username: user.username.trim(), password: user.password.trim() },
		}

		const res: any = await useAuthClient().get(Routes.AUTH_LOGIN, config)

		const token = res.data.data.access_token
		const refresh_token = res.data.data.refresh_token

		setBearerAuthorization(useAuthClient(), token)

		if (res.status == 200) {
			const payload: any = await getPayload()

			const roles = payload.data.data.payload.role

			setIsAuthenticated(token, refresh_token)

			setPermission(roles)

			setBearerAuthorization(useClient(), token)

			setContentType(useClient())
		}

		return res
	} catch (error: any) {
		return error
	}
}

export const getPayload = async () => {
	try {
		const res = await useAuthClient().get(Routes.AUTH_PAYLOAD)

		return res
	} catch (error: any) {
		return error.response.status
	}
}
