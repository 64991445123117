import { useClient } from "@/clients/AxiosClient"
import { Routes } from "@/utils/enum"

export const getAdjustments = async (base: string, idCommission: number) => {
	try {
		const res = await useClient().get(
			`${Routes.GET_ADJUSTMENTS}?base=${base}&idCommission=${idCommission}`
		)

		return res.data
	} catch (error: any) {
		return error
	}
}

export const insertAdjustments = async (
	base: string,
	idCommission: number,
	adjustmentReason: string,
	value: number
) => {
	try {
		const data = {
			base,
			idCommission,
			adjustmentReason,
			value,
		}

		const res = await useClient().post(Routes.INSERT_ADJUSTMENTS, data)

		return res
	} catch (error) {
		return error
	}
}
