import { useAuthClient } from "@/clients/AxiosClient"
import { Routes } from "@/utils/enum"

export const recoveryPassword = async (username: string) => {
	try {
		const redirect_url = process.env.VUE_APP_REDIRECT_URL

		const response = await useAuthClient().post(Routes.AUTH_RESET_PASSWORD, {
			company: "prime",
			redirect_url,
			username,
		})

		return response
	} catch (error) {
		return error
	}
}
