/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import axios, { AxiosInstance } from "axios"
import { Credential } from "@/utils/interfaces"

import { RefreshTokenInterceptor } from "./Interceptors"

let restClient: AxiosInstance
let authClient: AxiosInstance

const refreshInterceptor = new RefreshTokenInterceptor()

export const setupClient = (
	baseUrl: string | undefined,
	authUrl: string | undefined
) => {
	restClient = axios.create({
		baseURL: baseUrl,
		validateStatus(status) {
			return status! < 500
		},
	})

	authClient = axios.create({
		baseURL: authUrl,
		validateStatus(status) {
			return status! < 500
		},
	})

	restClient.interceptors.request.use(
		refreshInterceptor.onRequest,
		refreshInterceptor.onError
	)

	restClient.interceptors.response.use(
		refreshInterceptor.onResponse,
		refreshInterceptor.onError
	)

	authClient.interceptors.request.use(
		refreshInterceptor.onRequest,
		refreshInterceptor.onError
	)

	authClient.interceptors.response.use(
		refreshInterceptor.onResponse,
		refreshInterceptor.onError
	)
}

export const setBearerAuthorization = (
	client: AxiosInstance,
	token: string
) => {
	client.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export const setBasicAuthorization = (
	client: AxiosInstance,
	credential: Credential
) => {
	client.defaults.auth = {
		username: credential.username.trim(),
		password: credential.password.trim(),
	}
}

export const setContentType = (client: AxiosInstance) => {
	client.defaults.headers.common["Content-Type"] = "application/json"
}

export const useClient = () => restClient
export const useAuthClient = () => authClient
