import {
	useAuthClient,
	setBearerAuthorization,
	useClient,
} from "@/clients/AxiosClient"
import { Routes } from "@/utils/enum"
import { getRefreshToken, setIsAuthenticated } from "@/utils/permissions"
import axios from "axios"

/* eslint-disable no-useless-catch */

export const getNewToken = async () => {
	try {
		const config: any = {
			method: "get",
			url: `${process.env.VUE_APP_AUTH}${Routes.REFRESH_TOKEN}`,
			headers: { Authorization: `Bearer ${getRefreshToken()}` },
		}

		const res = await axios(config)

		if (res?.status == 200) {
			const token = res.data.data.access_token
			const refresh_token = res.data.data.refresh_token

			setBearerAuthorization(useAuthClient(), token)
			setBearerAuthorization(useClient(), token)
			setIsAuthenticated(token, refresh_token)
			return token
		}

		throw new Error("Failed to retrieves new Tokens")
	} catch (error) {
		return null
	}
}
