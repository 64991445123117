export const SAVE_COMISSION_FILTER = "SAVE_COMISSION_FILTER"
export const SAVE_USER = "SAVE_USER"
export const SAVE_USER_REV = "SAVE_USER_REV"
export const SAVE_RESALE_CODE = "SAVE_RESALE_CODE"
export const SAVE_COMMISSION_PAGINATION = "SAVE_COMMISSION_PAGINATION"
export const SAVE_COMMISSION_ITENS_PER_PAGE = "SAVE_COMMISSION_ITENS_PER_PAGE"
export const SAVE_USER_TYPE = "SAVE_USER_TYPE"
export const SAVE_USER_PAGINATION = "SAVE_USER_PAGINATION"
export const SAVE_USER_ITENS_PER_PAGE = "SAVE_USER_ITENS_PER_PAGE"
export const RESET_STATES = "RESET_STATES"
export const SAVE_EXPIRED = "SAVE_EXPIRED"
export const SELECTED_COMPANY = "SELECTED_COMPANY"
