import { States } from "@/utils/interfaces"
import { InjectionKey } from "vue"
import { Store, createStore, useStore as vuexUseStore } from "vuex"
import { users } from "./users"
import { commission } from "./comissions"
import { Profile } from "@/utils/enum"
import { RESET_STATES, SELECTED_COMPANY } from "./mutationsTypes"

export const key: InjectionKey<Store<States>> = Symbol()

const initialState = {
	selectedCompany: "",
	commissionsFilter: {
		base: "",
		client: "",
		codRev: "",
		finalDate: "",
		initialDate: "",
		pageNum: 1,
		pageSize: 10,
		situation: "",
	},
	userInfo: [
		{
			name: "",
			username: "",
			resaleCode: "",
			id: "",
			email: "",
		},
	],
	userRev: {
		username: "",
		codRev: "",
		aidc: "",
		rbcode: "",
		name: "",
		id: "",
		email: "",
	},
	resaleCode: "",

	userType: Profile.COMMISSION,
	expired: 0,
}

const mutations = {
	[RESET_STATES](state: States) {
		Object.assign(state, initialState)
	},
	[SELECTED_COMPANY](state: States, company: string) {
		state.selectedCompany = company
	},
}

export const store = createStore<States>({
	state: { ...initialState },
	getters: {
		getCommissionFilter(state) {
			return state.commissionsFilter
		},
		getUserInfo(state) {
			return state.userInfo
		},
		getUsername(state) {
			return state.userRev.username
		},
		getCodRev(state) {
			return state.userRev.codRev
		},
		getCodAidc(state) {
			return state.userRev.aidc
		},
		getCodRbcode(state) {
			return state.userRev.rbcode
		},
		getResaleCode(state) {
			return state.resaleCode
		},
		getSituation(state) {
			return state.commissionsFilter.situation
		},
		getResaleName(state) {
			return state.userRev.name
		},
		getUserType(state) {
			return state.userType
		},
		getUserRev(state) {
			return state.userRev
		},
		getExpired(state) {
			return state.expired
		},
	},
	mutations: {
		...commission.mutations,
		...users.mutations,
		...mutations,
	},
	actions: {},
	modules: { users, commission },
})

export function useStore(): Store<States> {
	return vuexUseStore(key)
}
